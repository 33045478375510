import React from 'react';
import { Link } from 'react-router-dom';
import { Below } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import TrendLogo from './trend-logo.svg';

const StoreName = styled('h2')`
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  color: inherit;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
`;

const Logo = ({ searchOpen }) => (
  <Below breakpoint="md">
    {matches =>
      matches ? (
        <>
          {!searchOpen && (
            <StoreName>
              <Link to="/">
                <img alt="Trend" src={TrendLogo} />
              </Link>
            </StoreName>
          )}
        </>
      ) : (
        <StoreName>
          <Link to="/">
            <img alt="Trend" src={TrendLogo} />
          </Link>
        </StoreName>
      )
    }
  </Below>
);

export { Logo };
